import { FC, useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Button, Icon } from '@wmgtech/legato'
import classNames from 'classnames'

import LoadingButton from 'components/LoadingButton'
import currencyFormat from 'utils/currencyFormat'
import { useAuditQuery } from 'generated/graphql'
import NestedForm from './NestedForm'
import styles from './styles.module.scss'

interface IReserve {
  auditTypeId: string
  total: number
  labels: [
    {
      amount: string
      auditLabelId: string
      part: string
    }
  ]
}
interface IFormProps {
  onSubmit: (data: any) => void
  data: any
  onCreateBtnClick: (event: any) => void
  onEditClaimClick: (index: number) => void
  onDeleteClaimClick: (index: number) => void
  reserves: IReserve[] | null
  claimsTotalAmount: number
  allocationsTotalAmt: number
  onChangeAllocations: (data: number) => void
}

const AllocationsForm: FC<IFormProps> = ({
  onSubmit,
  data,
  onCreateBtnClick,
  onEditClaimClick,
  onDeleteClaimClick,
  reserves,
  claimsTotalAmount,
  allocationsTotalAmt,
  onChangeAllocations,
}) => {
  const { auditId } = useParams<{ auditId: string }>()
  const { data: auditData } = useAuditQuery({
    variables: { input: auditId! },
  })
  const auditTypes = auditData?.audit?.types

  const defaultValues = { claims: data }

  const methods = useForm({ defaultValues: defaultValues })

  const triggeFormRerender = useCallback(() => {
    methods.reset({ claims: data })
  }, [data, methods])

  useEffect(() => triggeFormRerender(), [data, triggeFormRerender])

  const { watch } = methods

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name?.includes('amount')) {
        let totalAmt = 0
        for (let claim of value?.claims) {
          for (let allocations of claim.allocations) {
            totalAmt += +allocations.amount
          }
        }

        onChangeAllocations(totalAmt)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch, onChangeAllocations])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
        <div className={styles.body}>
          <div className={styles.grid}>
            <span></span>
            <span>
              <b>Claim #</b>
            </span>
            <span>
              <b>Name</b>
            </span>
            <span>
              <b>Type/Sub</b>
            </span>
            <span>
              <b>Action</b>
            </span>
            <span>{reserves && <b>Current Reserve</b>}</span>
            <span></span>
            <span>
              <b>Total Claim</b>
            </span>
            <span>
              <b>Notes</b>
            </span>
            <span>
              <Button
                secondaryIcon="plus"
                colorType="success"
                onClick={onCreateBtnClick}
                size="sm"
              >
                Claim
              </Button>
            </span>
          </div>
          {data?.map((element: any, index: number) => {
            return (
              <div key={index}>
                <div className={classNames(styles.grid, styles.claim)}>
                  <span>
                    <small>
                      {
                        auditTypes?.find(
                          (t) =>
                            element.auditTypeId === t?.id ||
                            element.auditType?.id === t?.id
                        )?.name
                      }
                    </small>
                  </span>
                  <span>{element.number}</span>
                  <span>{element.name}</span>
                  <span>
                    {element.type?.title || element.type?.name || 'N/A'} /{' '}
                    {element.subcategory?.title ||
                      element.subcategory?.name ||
                      'N/A'}
                  </span>
                  <span>
                    {element.action?.title || element.action?.name || 'N/A'}
                  </span>
                  {reserves ? (
                    <span>
                      $
                      {
                        reserves.find(
                          (r) =>
                            r.auditTypeId === element.auditTypeId ||
                            r.auditTypeId === element.auditType?.id
                        )?.total
                      }
                    </span>
                  ) : (
                    <span></span>
                  )}
                  <span></span>
                  <span>{currencyFormat(element.amount)}</span>
                  <span></span>
                  <span>
                    <Button
                      containerStyle={'link'}
                      colorType={'primary'}
                      size="sm"
                      className={styles.button}
                      onClick={() => {
                        onEditClaimClick(index)
                      }}
                    >
                      <Icon name="edit" prefix="fas" />
                    </Button>
                    <span className={styles.separator}>|</span>
                    <Button
                      onClick={() => {
                        onDeleteClaimClick(index)
                      }}
                      containerStyle={'link'}
                      colorType={'danger'}
                      size="sm"
                      className={styles.button}
                    >
                      <Icon name="trash" prefix="fas" />
                    </Button>
                  </span>
                </div>
                <NestedForm claim={element} index={index} reserves={reserves} />
              </div>
            )
          })}
          {data?.length ? (
            <div>
              <div className={styles.grid}>
                <span></span>
                <span></span>
                <span>
                  <b>Claims Total</b>
                </span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span>{currencyFormat(claimsTotalAmount)}</span>
                <span></span>
              </div>
              <div className={styles.grid}>
                <span></span>
                <span></span>
                <span>
                  <b>Allocations Total</b>
                </span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span>{currencyFormat(allocationsTotalAmt)}</span>
                <span></span>
              </div>
            </div>
          ) : (
            <div className={styles.empty}>No claims exist</div>
          )}
        </div>
        {data?.length ? (
          <footer className={styles.footer}>
            <LoadingButton
              type="submit"
              containerStyle={'outline'}
              colorType={'primary'}
            >
              Save Revision
            </LoadingButton>
          </footer>
        ) : (
          ''
        )}
      </form>
    </FormProvider>
  )
}

export default AllocationsForm
