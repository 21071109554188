import { FC, Suspense, useCallback, useState } from 'react'
import { Accordion } from '@wmgtech/legato'

import Spinner from 'components/Spinner'
import RevisionForm from '../../RevisionForm'
import ClaimModal from '../ClaimModal'
import AllocationsForm from '../../AllocationsForm/AllocationsForm'
import EditClaimModal from '../EditClaimModal/EditClaimModal'
import { getTotalReserveAmountByAuditType } from 'utils/reserveCalculations'
import {
  AuditDocument,
  useSaveAuditClaimsRevisionMutation,
} from 'generated/graphql'
import {
  calculateAllocations,
  calculateUnionAllocations,
} from 'utils/claimsCalculations'
import notify from 'utils/notify'
import { Notification } from 'constants/typeConstants'
import SubmissionModal from 'components/SubmissionModal'
import FormPrompt from 'components/Form/FormPrompt/FormPrompt'
import styles from './styles.module.scss'

const convertToDate = (date: string | null) => {
  const newDate = date ? new Date(date) : null
  return newDate
}

interface IAllocation {
  amount: string
  auditLabelId: string
  notes?: string
}

interface IClaim {
  id?: string
  amount: string
  number?: string
  name: string
  actionId?: string
  auditTypeId: string
  typeId?: string
  subcategoryId?: string
  notes?: string
  allocations: IAllocation[]
}

interface IPayloadSaveRevision {
  revisionId: string
  claims: IClaim[]
}

const Revision: FC<any> = ({
  revisionId,
  revisions,
  updateRevision,
  reserves,
  auditData,
}) => {
  const activeRevision = revisions.find(
    (revision: any) => revision.id === revisionId
  )

  const labels = auditData?.audit?.labels

  const types = auditData?.audit?.types.map((type: any) => type.subcategoryId)
  const isUnion = types.includes('1' || '8')

  const reservesTotal = getTotalReserveAmountByAuditType(
    reserves?.lastAuditReservedAmounts
  )

  const activeRevisionClaims =
    [...activeRevision?.claims].sort((a: any, b: any) =>
      a.number?.localeCompare(b.number)
    ) ?? []

  const [claims, setClaims] = useState<any>(activeRevisionClaims)
  const [unsavedChanges, setChanges] = useState(false)
  const [showSubmissionModal, setShowSubmissionModal] = useState(false)
  const toggleSubmissionModal = useCallback(() => {
    setShowSubmissionModal((show) => !show)
  }, [])

  const initialTotalAllocation = (claims: any) => {
    let total = 0
    if (claims?.length) {
      for (let claim of claims) {
        for (let allocations of claim.allocations) {
          total += +allocations.amount
        }
      }
    }
    return total
  }

  const onChangeAllocations = (data: number) => {
    setChanges(true)
    setInitialTotalAllocations(data)
  }

  const [initialTotalAllocationAmt, setInitialTotalAllocations] = useState(
    initialTotalAllocation(claims)
  )

  const defaultValues = {
    revisionDate: convertToDate(activeRevision.revisionDate),
    notes: activeRevision.notes,
    id: activeRevision.id,
  }

  const [showClaimModal, setShowClaimModal] = useState(false)
  const toggleClaimModal = useCallback(() => {
    setShowClaimModal((show) => !show)
  }, [])

  const [showEditClaimModal, setShowEditClaimModal] = useState(false)
  const toggleEditClaimModal = useCallback(() => {
    setShowEditClaimModal((show) => !show)
  }, [])

  const setClaimForUpdate = (index: number) => {
    setActiveClaim(claims[index])
    toggleEditClaimModal()
  }

  const setClaimForDeletion = (index: number) => {
    setActiveClaim(claims[index])
    toggleSubmissionModal()
  }

  const [activeClaim, setActiveClaim] = useState<any>(null)
  const [saveAuditRevision] = useSaveAuditClaimsRevisionMutation()

  const [claimsTotalAmount, setTotalClaimsAmount] = useState<any>(
    activeRevision.claims
      ?.map((d: any) => d.amount)
      .reduce((acc: any, cur: any) => +acc + +cur, 0)
  )

  const calculateTotalClaim = (data: any) => {
    const amount = data
      .map((d: any) => d.amount)
      .reduce((acc: any, cur: any) => +acc + +cur, 0)
    setTotalClaimsAmount(amount)
  }

  const changeClaims = (claims: any) => {
    claims.sort((a: any, b: any) => a.number?.localeCompare(b.number))
    setClaims(claims)
    calculateTotalClaim(claims)
    const newAllocations = initialTotalAllocation(claims)
    setInitialTotalAllocations(newAllocations)
    setChanges(true)
  }

  const createClaim = (data: any) => {
    data.allocations = isUnion
      ? calculateUnionAllocations(labels)
      : calculateAllocations(labels, reservesTotal, data)
    const newClaims = claims.concat(data)
    toggleClaimModal()
    changeClaims(newClaims)
  }

  const updateClaim = (claim: any) => {
    claim.allocations = isUnion
      ? calculateUnionAllocations(labels)
      : calculateAllocations(labels, reservesTotal, claim)
    const newClaims = [
      ...(claims as any[]).filter((c: any) => {
        return c.name !== claim.name
      }),
      claim,
    ]
    toggleEditClaimModal()
    changeClaims(newClaims)
  }

  const deleteClaim = () => {
    const newClaims = [
      ...(claims as any[]).filter((c: any) => {
        return c.name !== activeClaim.name
      }),
    ]
    toggleSubmissionModal()
    changeClaims(newClaims)
  }

  const saveRevision = (allocationsData: any) => {
    const claimsPayload: IClaim[] = []
    for (let i = 0; i < claims.length; i++) {
      const allocations: IAllocation[] = []
      for (let alloc of allocationsData.claims[i].allocations) {
        const { amount, auditLabelId, notes } = alloc
        allocations.push({
          amount: amount.toString(),
          notes,
          auditLabelId: auditLabelId ?? auditLabelId.id,
        })
      }
      const {
        action,
        type,
        subcategory,
        key,
        auditType,
        __typename,
        id,
        ...rest
      } = claims[i]
      const claimPayload = {
        ...rest,
        auditTypeId: claims[i].auditTypeId ?? auditType?.id,
        allocations,
        actionId: action?.key || undefined,
        typeId: type?.key || undefined,
        subcategoryId: subcategory?.key || undefined,
      }
      claimsPayload.push(claimPayload)
    }

    const payload: IPayloadSaveRevision = {
      revisionId: activeRevision.id,
      claims: claimsPayload,
    }

    saveAuditRevision({
      variables: {
        input: payload,
      },
      refetchQueries: [
        {
          query: AuditDocument,
          variables: { input: auditData?.audit?.id },
        },
      ],
      onCompleted() {
        setChanges(false)
        notify(Notification.SUCCESS, 'You have saved revision')
      },
      onError() {
        notify(Notification.ERROR, 'There is some error while saving revision')
      },
    })
  }

  return (
    <>
      <FormPrompt hasUnsavedChanges={unsavedChanges} />
      <ClaimModal
        onSubmit={createClaim}
        onClose={toggleClaimModal}
        show={showClaimModal}
        claims={claims || []}
      />
      <EditClaimModal
        onSubmit={updateClaim}
        onClose={toggleEditClaimModal}
        show={showEditClaimModal}
        claims={claims || []}
        claim={activeClaim}
      />
      <SubmissionModal
        name="claim"
        show={showSubmissionModal}
        onClose={toggleSubmissionModal}
        onSubmit={deleteClaim}
      />
      <Accordion
        className={styles.accordion}
        list={[
          {
            title: 'Total Revision Details',
            content: (
              <Suspense fallback={<Spinner name="circle-notch" />}>
                <RevisionForm
                  onSubmit={updateRevision}
                  defaultValues={defaultValues}
                  revisionId={revisionId}
                />
              </Suspense>
            ),
            id: 0,
            expand: false,
          },
        ]}
      />
      <div className="divider"></div>

      <AllocationsForm
        reserves={isUnion ? null : reservesTotal}
        data={claims}
        onSubmit={saveRevision}
        onCreateBtnClick={toggleClaimModal}
        onEditClaimClick={setClaimForUpdate}
        onDeleteClaimClick={setClaimForDeletion}
        claimsTotalAmount={claimsTotalAmount}
        allocationsTotalAmt={initialTotalAllocationAmt}
        onChangeAllocations={onChangeAllocations}
      ></AllocationsForm>
    </>
  )
}

export default Revision
