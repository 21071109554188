import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { Input } from '@wmgtech/legato'

import styles from './styles.module.scss'

interface IReserve {
  auditTypeId: string
  total: number
  labels: [
    {
      amount: string
      auditLabelId: string
      part: string
    }
  ]
}

interface IAllocation {
  amount: string
  notes: string
  auditLabelId?: string
  auditLabelName: string
  auditLabel?: {
    id: string
    abbreviation: string
  }
  part?: string
}

interface IAllocationFormProps {
  claim: {
    auditTypeId?: string
    auditType?: {
      id: string
    }
    allocations: IAllocation[]
  }
  index: number
  reserves: IReserve[] | null
}

const NestedForm: FC<IAllocationFormProps> = ({ claim, index, reserves }) => {
  const { register } = useFormContext()

  const findLabelPart = (allocation: IAllocation) => {
    return reserves
      ?.find(
        (r) =>
          r.auditTypeId === claim.auditTypeId ||
          r.auditTypeId === claim.auditType?.id
      )
      ?.labels.find(
        (l) =>
          l.auditLabelId === allocation.auditLabelId ||
          l.auditLabelId === allocation?.auditLabel?.id
      )?.part
  }

  return (
    <>
      {claim.allocations.map((allocation, i) => {
        return (
          <div key={i} className={styles.grid}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span>
              {reserves
                ? allocation.part || findLabelPart(allocation) + '%'
                : null}
            </span>
            <span className={styles.label}>
              <small>
                {allocation.auditLabelName ??
                  allocation?.auditLabel?.abbreviation}
              </small>
            </span>
            <input
              hidden
              {...register(`claims[${index}].allocations[${i}].auditLabelId`)}
              defaultValue={
                allocation.auditLabelId || allocation.auditLabel?.id
              }
            />
            <span>
              <Input
                {...register(`claims[${index}].allocations[${i}].amount`)}
                type="number"
                step="any"
              />
            </span>
            <span>
              <Input
                {...register(`claims[${index}].allocations[${i}].notes`)}
              />
            </span>
          </div>
        )
      })}
    </>
  )
}

export default NestedForm
