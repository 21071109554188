import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Input, InputGroup } from '@wmgtech/legato'
import classNames from 'classnames'

import LoadingButton from 'components/LoadingButton'
import FormSelect from 'components/Form/FormSelect'
import convertToList from 'utils/convertToList'
import {
  ClaimActionsQuery,
  ClaimSubcategoriesQuery,
  ClaimTypesQuery,
  useClaimActionsQuery,
  useClaimSubcategoriesQuery,
  useClaimTypesQuery,
} from 'generated/graphql'
import useData from 'hooks/useData'
import FormInputValidated from 'components/Form/FormInputValidated'
import styles from './styles.module.scss'

interface IFormProps {
  onClose?: () => void
  onSubmit: (data: any) => void
  defaultValues?: any
  auditData: any
}

const ClaimForm: FC<IFormProps> = ({
  onClose,
  onSubmit,
  defaultValues,
  auditData,
}) => {
  const auditCategories = auditData?.audit?.types.map(
    (type: any) => type.subcategoryId
  )
  const getAuditTypesWithReserves = () => {
    const auditTypes = []
    for (let res of auditData?.audit?.reservedAmounts) {
      if (!auditTypes.map((type) => type.id).includes(res.audit.auditType.id)) {
        auditTypes.push(res.audit.auditType)
      }
    }
    return auditTypes
  }

  const auditTypes = auditCategories.includes('1' || '8')
    ? convertToList(auditData?.audit?.types)
    : convertToList(getAuditTypesWithReserves())

  const typesData = useClaimTypesQuery()
  const claimTypesDataList = useData<ClaimTypesQuery>({
    loading: typesData.loading,
    previousData: typesData.previousData,
    data: typesData.data,
  })
  const claimTypes = convertToList(claimTypesDataList?.claimTypes)

  const subData = useClaimSubcategoriesQuery()
  const claimSubDataList = useData<ClaimSubcategoriesQuery>({
    loading: subData.loading,
    previousData: subData.previousData,
    data: subData.data,
  })
  const claimSubcategories = convertToList(claimSubDataList?.claimSubcategories)

  const actionsData = useClaimActionsQuery()
  const claimActionsDataList = useData<ClaimActionsQuery>({
    loading: actionsData.loading,
    previousData: actionsData.previousData,
    data: actionsData.data,
  })
  const claimActions = convertToList(claimActionsDataList?.claimActions)

  const methods = useForm({ defaultValues: defaultValues })
  const { register } = methods

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <div className={styles.body}>
            <div className={classNames('form-group', 'form-group-four')}>
              <InputGroup
                className={styles['input-group']}
                label="Claim Number"
              >
                <Input
                  {...register('number')}
                  placeholder="Claim sorting order"
                />
              </InputGroup>
              <FormInputValidated
                defaultValue={defaultValues?.name}
                name="name"
                label="Name of Claim"
                placeholder="Short name of claim"
                message="Name can't be blank"
                rules={{ required: true }}
              ></FormInputValidated>
              <FormInputValidated
                defaultValue={defaultValues?.amount}
                name="amount"
                label="Amount"
                type="number"
                placeholder="Total claim amt"
                message="Amount can't be blank"
                rules={{ required: true }}
              ></FormInputValidated>
              <InputGroup label="Audit Type">
                <FormSelect
                  value={defaultValues?.auditTypeId}
                  name="auditTypeId"
                  list={auditTypes ?? []}
                  placeholder="Select a type"
                  disabled={auditTypes?.length === 1}
                />
              </InputGroup>
            </div>
            <div className={classNames('form-group', 'form-group-four')}>
              <InputGroup label="Claim Type">
                <FormSelect
                  isobject="true"
                  name="type"
                  list={claimTypes ?? []}
                  placeholder="Select a type"
                />
              </InputGroup>
              <InputGroup label="Claim Subcategory">
                <FormSelect
                  isobject="true"
                  name="subcategory"
                  list={claimSubcategories ?? []}
                  placeholder="Select a subcategory"
                />
              </InputGroup>
              <InputGroup label="Claim Action">
                <FormSelect
                  isobject="true"
                  name="action"
                  list={claimActions ?? []}
                  placeholder="Select an action"
                />
              </InputGroup>
              <div></div>
            </div>
          </div>
          <footer className={styles.footer}>
            {onClose && (
              <LoadingButton
                containerStyle={'outline'}
                colorType={'secondary-black'}
                onClick={onClose}
              >
                Cancel
              </LoadingButton>
            )}
            <LoadingButton
              type="submit"
              containerStyle={'outline'}
              colorType={'primary'}
            >
              {defaultValues.name ? 'Update Claim' : 'Create Claim'}
            </LoadingButton>
          </footer>
        </form>
      </FormProvider>
    </>
  )
}

export default ClaimForm
