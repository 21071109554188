interface IAllocation {
  auditLabelId: string,
  auditLabelName: string,
  amount: number | string,
  notes: string,
  part: string
}

export const calculateAllocations = (labels: any[], reservesTotal: any, claim: any): IAllocation[] => {
  const allocations: IAllocation[] = []

  const reserve = reservesTotal
    .find((res: any) => claim.auditTypeId === res.auditTypeId)

  for (let label of reserve.labels) {
    const amount = label ? (label.part / 100) * claim.amount : 0
    const auditLabel = labels.find((l: any) => l.id === label.auditLabelId)
    const allocation = {
      auditLabelId: label.auditLabelId,
      auditLabelName: auditLabel.abbreviation,
      amount: +amount.toFixed(2),
      notes: '',
      part: label ? label.part : '0.00',
    }
    allocations.push(allocation)
  }
  return allocations
}

export const calculateUnionAllocations = (labels: any[]): IAllocation[] => {
  const allocations: IAllocation[] = []
  for (let label of labels) {
    const allocation = {
      auditLabelId: label.id,
      auditLabelName: label.abbreviation,
      amount: (0).toFixed(2),
      notes: '',
      part: label ? label.part : '0.00',
    }
    allocations.push(allocation)
  }
  return allocations
}
